import { mapState } from 'vuex';
import KnDuplicatePlanModal from '../../components/KnDuplicatePlanModal/KnDuplicatePlanModal.vue';
import KnLocalAlert from '../../../shared/components/KnLocalAlert.vue';
import KnTable from '../../../shared/components/KnTable.vue';
import {
  canAdd,
  canChange,
  canDelete,
  insufficientPermissionsMessage,
} from '../../../shared/helpers/permissionsUtils';
import { paginationMixin } from '../../../shared/mixins/paginationMixin';

import {
  fetchSyllabus,
  delSyllabus,
  reactivateSyllabus,
  duplicateSyllabus,
} from '../../helpers/syllabusOptions';
import { formatStringDateToLocaleDate } from '../../../shared/helpers/dateUtils';

export default {
  name: 'SyllabusIndex',
  components: { KnDuplicatePlanModal, KnLocalAlert, KnTable },
  mixins: [paginationMixin],
  props: {},
  data() {
    return {
      syllabus: [],
      headers: [
        {
          text: 'Fecha de elaboración',
          value: 'fecha_elaboracion',
          class: 'purple--text',
        },
        { text: 'Nombre', value: 'nombre', class: 'purple--text' },
        {
          text: 'Fecha inicio',
          value: 'fechaInicio',
          class: 'purple--text',
        },
        { text: 'Fecha fin', value: 'fechaFin', class: 'purple--text' },
        {
          text: 'Nivel educativo',
          value: 'nivel_educativo.nombre',
          class: 'purple--text',
        },
        {
          text: 'Ciclo escolar',
          value: 'ciclo_escolar.dato',
          class: 'purple--text',
        },
        { text: 'ID', value: 'id', class: 'purple--text' },
        { text: '', value: 'acciones', class: 'purple--text' },
      ],
      inactiveItems: [],
      showInactive: false,
      loading: false,
      selectedAction: '',
      itemToEdit: null,
      /** Variables para alerta */
      errors: [],
      warnings: [],
      loadingAlert: false,
      showAlert: false,
      alertType: 'info',
      alertText: insufficientPermissionsMessage(),
      alertColor: 'warning',
      /*********************** */
      showDuplicate: false,
      duplicateName: '',
    };
  },
  computed: {
    ...mapState(['institutionId', 'userData', 'search']),
    buttomActionText() {
      return this.showInactive ? 'Ver Planes Activos' : 'Ver Planes Inactivos';
    },
    tableTitle() {
      return this.showInactive ? 'Planes Inactivos' : 'Planes';
    },
  },
  watch: {
    search: {
      async handler() {
        await this.getPaginatedSyllabus(1);
      },
    },
    showInactive: {
      async handler() {
        await this.getPaginatedSyllabus(1);
      },
    },
    institutionId: {
      async handler() {
        await this.getPaginatedSyllabus(1);
      },
    },
  },
  async created() {
    await this.getPaginatedSyllabus(1);
  },
  methods: {
    canAdd: canAdd,
    async getPaginatedSyllabus(page = 1) {
      this.setPaginationPage(page);
      if (!this.loading) {
        this.syllabus = [];
        this.loading = true;
        const { ok, data, message, count } = await fetchSyllabus({
          institutionId: this.institutionId,
          name: this.search,
          systemStatus: !this.showInactive,
          limit: this.pagination.limit,
          offset: this.pagination.offset,
        });
        if (ok) {
          this.setPaginationCount(count);
          const syllabusResults = data;
          for (const s of syllabusResults) {
            const item = {
              ...s,
              fecha_elaboracion: formatStringDateToLocaleDate({
                stringDate: s.fecha_elaboracion,
              }),
              fechaInicio: formatStringDateToLocaleDate({
                stringDate: s.fecha_inicio,
              }),
              fechaFin: formatStringDateToLocaleDate({
                stringDate: s.fecha_fin,
              }),
            };
            this.syllabus.push(item);
          }
        } else {
          console.error('Error al obtener planes. ', message);
        }
        this.loading = false;
      }
    },
    openAlert(obj) {
      // console.log('Open modal', obj);
      // console.log('canChange', canChange('planestudios'));

      if (canChange('planestudios')) {
        this.selectedAction = obj.action;
        // console.log('Puede editar planes');
        this.alertType = 'success';
        this.alertColor = 'error';
        this.itemToEdit = this.syllabus.find((i) => i.id === obj.itemId);
        if (obj.action === 'corregir') {
          this.alertText =
            'Estas a punto de "Editar" un plan de estudios. Deseas continuar?';
        } else if (obj.action === 'duplicar') {
          this.alertText =
            'Estas a punto de "Duplicar" un plan de estudios. Deseas continuar?';
        }
      } else {
        // console.log('No puede editar planes');
        this.alertType = 'info';
        this.alertText = insufficientPermissionsMessage();
        this.alertColor = 'warning';
      }
      this.showAlert = true;
    },
    actionAlertBtn1() {
      this.closeAlert();
    },
    actionAlertBtn2() {
      this.showAlert = false;
      if (this.selectedAction === 'corregir') {
        this.$router.push({
          name: 'Editar Plan',
          params: {
            entity: this.itemToEdit,
            readonly: false,
          },
        });
      } else if (this.selectedAction === 'duplicar') {
        // TODO: Abrir modal para permitir asignar nombre al plan
        this.showDuplicate = true;
      }
    },
    closeAlert() {
      this.itemToEdit = null;
      this.showAlert = false;
    },
    async action2(syllabusId) {
      if (this.showInactive) {
        await this.enableSyllabus(syllabusId);
      } else {
        await this.disableSyllabus(syllabusId);
      }
    },
    async disableSyllabus(syllabusId) {
      try {
        if (canDelete('planestudios')) {
          const { ok, message } = await delSyllabus(syllabusId);
          if (ok) {
            await this.getPaginatedSyllabus(1);
          } else {
            console.log('No se pudo desactivar el plan. ', message);
          }
        } else {
          this.showAlert = true;
        }
      } catch (error) {
        console.error('Error al intentar desactivar plan. ', error);
      }
    },
    async enableSyllabus(syllabusId) {
      try {
        if (canChange('planestudios')) {
          const { ok, message } = await reactivateSyllabus(syllabusId);
          if (ok) {
            await this.getPaginatedSyllabus(1);
          } else {
            console.log('No se pudo reactivar plan. ', message);
          }
        } else {
          this.showAlert = true;
        }
      } catch (error) {
        console.error('Error al intentar reactivar plan. ', error);
      }
    },
    closeDuplicate() {
      this.showDuplicate = false;
    },
    async duplicate(plan) {
      try {
        this.loadingAlert = true;
        if (canAdd('planestudios')) {
          const { ok, message } = await duplicateSyllabus(plan);
          if (ok) {
            await this.getPaginatedSyllabus(1);
            this.showDuplicate = false;
          } else {
            console.log('No se pudo duplicar plan', message);
          }
        }
      } catch (error) {
        console.error('Error al intentar duplicar plan. ', error);
      } finally {
        this.loadingAlert = false;
      }
    },
  },
};
