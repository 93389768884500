import KnTextField from '../../../shared/components/KnTextField.vue';
export default {
  name: 'KnDuplicatePlanModal',
  components: { KnTextField },
  mixins: [],
  props: {
    loadingAlert: {
      type: Boolean,
      default: false,
    },
    plan: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      titlePlan: '',
    };
  },
  computed: {},
  created() {},
  methods: {
    action1() {
      this.$emit('cancel');
    },
    action2() {
      this.$emit('accept', {
        id: this.plan.id,
        name: this.titlePlan,
      });
    },
  },
};
